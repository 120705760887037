<template>
  <!-- 议事协商 -->
  <div class="wrapper">
    <div class="layouter">
      <div class="search-box">
        <input type="text" name="search" v-model="title" class="search-inpt" placeholder="请输入关键字搜索">
        <div @click="serachList()">
          <img src="@/assets/icon_search.png" alt="" class="search-icon">
        </div>
      </div>
    </div>
    <van-skeleton v-if="currentList==-1" title :row="3" />
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getList"
    >
      <div class="diyCell" v-for="item in list" :key="item.id" @click="goDetail(item.id)">
        <div class="top fx_ai">
          <div class="title van-ellipsis fx1">{{ item.title }}</div>
          <van-tag size="mini" :color="item.status == '2'? '#FBE7E6' : '#DAF5DC'" :text-color="item.status == '2'? '#E35D58' : '#0DB918' ">{{ item.status == '2'?'已结束':'进行中' }}</van-tag>
        </div>
        <div class="time">
          <span>{{ item.createTime }}</span>
        </div>
        <p class="van-multi-ellipsis--l3">{{ item.contentDesc }}</p>
        <van-divider style="margin: .21rem 0" />
        <div style="text-align: right;">
          <van-button style="padding:.32rem" type="info" size="mini">立即参与</van-button>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { getList } from '@/api/discussionApi'
  export default {
    data(){
      return {
        list: [],
        loading: false,
        finished: false,
        page: 1,
        currentList: -1,
        title: "",
      }
    },
    created(){
    },
    methods:{
      serachList(){
        this.page = 1
        this.currentList = -1
        this.list = []
        this.finished = false
        this.getList()
      },
      getList() {
        if (this.currentList !== 0) {
          getList({
            openid: this.$store.getters.openId,
            current: this.page,
            size: 10,
            title: this.title,
          }).then(res => {
            if (res.code === 200) {
              this.loading = false
              this.currentList = res.data.records ? res.data.records.length : 0
              this.list = this.list.concat(res.data.records)
              this.page++ 
            }
          })
        } else {
          this.finished = true; 
        }
      },
      goDetail(id) {
        let path = '/discussionDetail?id='
        this.$router.push({
          path: path + id
        }) 
      },
    },
  }
</script>
<style scoped lang='scss'>
@import '@/style/discussList.scss';
</style>