import axios from '@/utils/axios';
const baseURL = '/api'
// 议事协商

// 议事协商列表
export const getList = (params) => {
  return axios({
    baseURL,
    url: '/discussion/discussionconsultation/page',
    method: 'get',
    params
  });
};
// 议事协商详情
export const getDeatil = (params) => {
  return axios({
    baseURL,
    url: '/discussion/discussionconsultation/getDeatil',
    method: 'get',
    params
  });
};
// 议事协商评论列表
export const discussioncomment = (params) => {
  return axios({
    baseURL,
    url: '/discussion/discussioncomment/page',
    method: 'get',
    params
  });
};
// 议事协商评论添加
export const disSubmit = (data) => {
  return axios({
    baseURL,
    url: '/discussion/discussioncomment/submit',
    method: 'post',
    data
  });
};
// 议事协商评论添加
export const remove = (params) => {
  return axios({
    baseURL,
    url: '/discussion/discussioncomment/remove',
    method: 'post',
    params
  });
};